<template>
    <div v-if="mountedAll" class="edit-style">
        <StackRouterHeaderBar
            :class="{ 'bottom-border': showHeaderTitle }"
            :title="$translate('IDEAL_TYPE')"
            :show-title="true"
            :left-button="'close'"
        />
        <main class="main edit-style-main" @scroll="onScroll">
            <div class="question">
                <div class="c-black f-16 spoqa-f-medium m-b-2 required">우선 가치</div>
                <div class="c-grey-07 f-12 m-b-16">
                    소개받을 시 외적인 요소(외모, 체형, 키)와 조건(직업, 경제력, 학업 등) 중 어떤 것에 더 가중치를
                    두시나요?
                </div>
                <div class="preference">
                    <div class="selector m-r-14 m-l-14">
                        <div class="line" />
                        <div
                            class="circle"
                            :class="`circle-${i}`"
                            v-for="i in [1, 2, 3, 4, 5, 6]"
                            @click="style.priority = i"
                            :key="i"
                        >
                            <div class="inner-circle" v-if="style.priority === i" />
                        </div>
                    </div>
                    <div class="label m-t-6">
                        <div class="f-12 c-grey-07">외적인 요소</div>
                        <div class="f-12 c-grey-07">조건</div>
                    </div>
                </div>
            </div>
            <div class="hr thick m-t-32 m-b-32" />
            <Slider
                v-if="ageRange.length"
                ref="ageSlider"
                :title="'나이'"
                :range="ageRange"
                :myRange="ageValue"
                :unit="'세'"
                :required="true"
                :slider-options="ageSliderOptions"
                :rangeDefault="'선택해주세요'"
                @change="updateStyleChanged"
            />
            <Slider
                v-if="heightRange.length"
                ref="heightSlider"
                :title="'키'"
                :range="heightRange"
                :myRange="heightValue"
                :unit="'cm'"
                :required="true"
                :rangeDefault="'선택해주세요'"
                :slider-options="heightSliderOptions"
                @change="updateStyleChanged"
            />
            <div class="types">
                <div @click="editType(type.key)" class="item" :key="type.key" v-for="type in types">
                    <div class="key required" v-html="$translate(type.title)" />
                    <div class="value" v-html="$translate(type.value)" />
                </div>
            </div>
            <div class="hr thick m-b-32" />
            <EditIntro :childOf="'style'" v-model="style.intro" />
        </main>
        <BottomButton :needAddInfoTop="false" :disabled="disabled" @click="submit" :label="$translate('SAVE')" />
    </div>
</template>

<script>
import EditIntro from './components/EditIntro'
import userService from '@/services/user'
import matchPreference from '@/assets/constants/match-preference'

export default {
    name: 'EditStylePage',
    components: { EditIntro },
    data: () => ({
        mountedAll: false,
        ageValue: [],
        ageRange: [],
        heightRange: [],
        ageSliderOptions: {
            min: 25,
            max: 49,
            tooltip: 'none',
        },
        heightValue: [],
        heightSliderOptions: {
            min: 140,
            max: 199,
            tooltip: 'none',
        },
        // types: [],
        styleChanged: false,
        showHeaderTitle: false,
    }),
    watch: {
        style: {
            deep: true,
            handler: 'updateStyleChanged',
        },
    },
    created() {
        this.init()
        this.$registerBackHandler(this.back)
    },
    beforeDestroy() {
        this.save()
        if (this.styleChanged) this.$toast.success('SAVED')
        this.$unregisterBackHandler()
    },
    computed: {
        me() {
            return this.$store.getters.me || {}
        },
        style() {
            return this.me.style || {}
        },
        multiSelectItems() {
            return ['university', 'religion', 'force']
        },
        needAddInfoTop() {
            return `<p class='f-12 c-grey-07'>*매니저에게만 공유되는 정보입니다.</p>
                <p class='f-12 c-grey-07'>프로필에 표시되지 않아요</p>`
        },
        disabled() {
            const notAnswered = ['priority', 'max_age', 'min_age', 'max_height', 'min_height'].every(
                i => !this.style[i]
            )
            const notAnsweredSmoking = this.style.smoking_type === null ? true : false
            const emptyListAnswer = this.multiSelectItems.every(i => !this.style[`${i}_type`].length)

            return notAnswered && notAnsweredSmoking && emptyListAnswer
        },
        types() {
            return ['smoking', 'religion', 'university'].map(key => ({
                key,
                title: `${key.toUpperCase()}_TYPE`,
                value: this.mountedAll ? this.typeTitle(key) : '',
            }))
        },
    },
    methods: {
        submit() {
            this.save().then(() => this.$stackRouter.pop())
        },
        multiSelect(key) {
            return ['religion_type', 'university_type'].includes(key)
        },
        typeTitle(key) {
            let value = ''
            if (this.multiSelect(`${key}_type`)) {
                const arr = this.style[`${key}_type`] || []
                arr.forEach(item => {
                    value += `${item.title}, `
                })
                value = value.slice(0, value.length - 2)
            } else {
                value = (matchPreference[key].find(t => t.value === this.style[`${key}_type`]) || {}).title
            }

            if (value) {
                return `<span style='max-width: 240px;' class="c-primary f-medium lines-1">${value}</span><i class='material-icons f-16'>chevron_right</i>`
            } else {
                return `<i class='material-icons f-16'>chevron_right</i>`
            }
        },
        editType(key) {
            this.$modal
                .custom({
                    component: 'ModalEditStyleType',
                    options: {
                        key,
                    },
                })
                .then(response => {
                    if (!response) return
                    const { items, force } = response
                    if (this.multiSelect(`${key}_type`)) {
                        this.style[`${key}_type`] = items.filter(item => item.$$selected)
                    } else {
                        this.style[`${key}_type`] = items.find(item => item.$$selected).value
                    }
                    // 무조건 반영 로직
                    if ((this.style.force_type || []).some(force => force.title === key)) {
                        if (!force) this.style.force_type = this.style.force_type.filter(force => force.title !== key)
                    } else {
                        if (force) {
                            const forceObj = matchPreference.force.find(f => f.title === key)
                            this.style.force_type.push(forceObj)
                        }
                    }

                    // this.initTypes()
                })
        },
        async init() {
            await this.$store.dispatch('loadMe')
            this.ageValue = [
                // this.style.min_age || this.ageSliderOptions.min,
                // this.style.max_age || this.ageSliderOptions.max,
                this.style.min_age,
                this.style.max_age,
            ]
            this.heightValue = [this.style.min_height, this.style.max_height]

            this.ageRange = [this.ageSliderOptions.min, this.ageSliderOptions.max]
            this.heightRange = [this.heightSliderOptions.min, this.heightSliderOptions.max]
            // 다중선택 가능한 옵션
            this.multiSelectItems.forEach(key => {
                const typeArr = this.style[`${key}_type`] ? JSON.parse(this.style[`${key}_type`]) : []
                const styleType = (matchPreference[key] || []).filter(
                    item => typeArr.includes(item.value) && item.gender.includes(this.me.gender)
                )
                this.$set(this.style, `${key}_type`, styleType)
            })
            this.mountedAll = true
        },
        preparedPayload() {
            const form = new FormData()
            this.multiSelectItems.forEach(item => {
                const arr = []
                this.style[`${item}_type`].forEach(i => arr.push(i.value))

                const lastStr = item === 'religion' ? '_ids' : item === 'university' ? '_grades' : '_type'
                form.append(`${item}${lastStr}`, arr.length ? JSON.stringify(arr) : '')
            })

            form.append(
                'smoking_type',
                this.style.smoking_type ? this.style.smoking_type : this.style.smoking_type === 0 ? 0 : ''
            )
            form.append('priority', this.style.priority)
            form.append('max_age', this.style.max_age)
            form.append('min_age', this.style.min_age)
            form.append('intro', this.style.intro || '')
            form.append('max_height', this.style.max_height)
            form.append('min_height', this.style.min_height)

            return form
        },
        async save() {
            try {
                this.saveSliderData()
                if (this.style.smoking_type === undefined) {
                    this.$set(this.style, 'smoking_type', null)
                }
                await userService.createStyles(this.preparedPayload())
                this.$store.dispatch('loadMe')
                this.$store.dispatch('loadSettings')
            } catch (e) {
                console.log(e)
                this.$toast.error('선호하는 외모, 스펙, 흡연여부를 모두 입력해주세요')
            }
        },
        saveSliderData() {
            if (this.$refs.ageSlider.updated) {
                this.style.min_age = this.$refs.ageSlider.newRange[0]
                this.style.max_age = this.$refs.ageSlider.newRange[1]
            }
            if (this.$refs.heightSlider.updated) {
                this.style.min_height = this.$refs.heightSlider.newRange[0]
                this.style.max_height = this.$refs.heightSlider.newRange[1]
            }
        },
        updateStyleChanged() {
            this.styleChanged = true
        },
        onScroll(event) {
            this.showHeaderTitle = event.target.scrollTop >= 52
        },
        back() {
            this.$stackRouter.pop()
        },
    },
}
</script>

<style lang="scss" scoped>
.edit-style {
    $header-height: 52px;
    overflow: hidden;

    .bottom-border {
        border-bottom: 1px solid $grey-02;
    }

    .main {
        height: calc(100vh - #{$header-height} - 48px);
        padding-top: 20px;
        padding-bottom: 20px;
        overflow-y: auto;
    }

    .question {
        padding: 0 16px;

        .preference {
            .selector,
            .label {
                position: relative;
                @include between;
            }
            .circle {
                z-index: 2;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                border: 1px solid $grey-04;
                background: white;
                position: relative;

                &.circle-2,
                &.circle-5 {
                    width: 28px;
                    height: 28px;
                }
                &.circle-3,
                &.circle-4 {
                    width: 20px;
                    height: 20px;
                }

                .inner-circle {
                    width: 80%;
                    height: 80%;
                    border-radius: 50%;
                    background: $blue-primary;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translateX(-50%) translateY(-50%);
                }
            }
            .line {
                width: 100%;
                height: 1px;
                background: $grey-04;
                position: absolute;
                top: 50%;
            }
        }
    }

    .types {
        margin-top: 40px;

        .item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 20px;
        }

        .key {
            @include spoqa-f-medium;
            font-size: 16px;
            color: black;
            width: fit-content;
        }

        .value {
            @include spoqa-f-medium;
            @include center;
            color: $grey-05;
            font-size: 14px;

            i {
                margin-left: 8px;
            }
        }
    }

    .height-warning {
        color: $grey-05;
        letter-spacing: -0.2px;
        line-height: normal;
        font-size: 12px;
        text-align: center;
        @include f-regular;
    }

    hr {
        margin: 32px 0;
        background: $grey-02;
        height: 1px;
        border-bottom: none;
    }

    .required::after {
        content: ' *';
        color: #ff3d6b;
    }
}
</style>
